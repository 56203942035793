import React, { useState } from "react";
import { useDemo } from "./hooks/useDemo";
import styles from "./css/Demo.module.css"; // Import the CSS module

const Demo: React.FC = () => {
  const {
    gender,
    handleGenderChange,
    age,
    handleAgeChange,
    category,
    handleCategoryChange,
    handleSearch,
    interestResults,
    localTrendResult,
    localPopulationResult,
    setAge,
    setGender,
    setCategory,
  } = useDemo();
  const [showResults, setShowResults] = useState(false);

  type Districts =
    | "종로구"
    | "중구"
    | "용산구"
    | "성동구"
    | "광진구"
    | "동대문구"
    | "중랑구"
    | "성북구"
    | "강북구"
    | "도봉구"
    | "노원구"
    | "은평구"
    | "서대문구"
    | "마포구"
    | "양천구"
    | "강서구"
    | "구로구"
    | "금천구"
    | "영등포구"
    | "동작구"
    | "관악구"
    | "서초구"
    | "강남구"
    | "송파구"
    | "강동구";
  interface InterestResult {
    localName: Districts;
    population: number;
  }

  const outDoorAd: Record<Districts, number> = {
    종로구: 14,
    중구: 40,
    용산구: 12,
    성동구: 5,
    광진구: 6,
    동대문구: 1,
    중랑구: 1,
    성북구: 4,
    강북구: 1,
    도봉구: 0,
    노원구: 4,
    은평구: 0,
    서대문구: 5,
    마포구: 18,
    양천구: 0,
    강서구: 4,
    구로구: 0,
    금천구: 0,
    영등포구: 10,
    동작구: 24,
    관악구: 5,
    서초구: 17,
    강남구: 70,
    송파구: 2,
    강동구: 1,
  };

  const handleSearchClick = () => {
    if (gender.length === 0 || age.length === 0 || category === "") {
      alert("성별, 연령대, 카테고리를 모두 선택해주세요.");
      return;
    }

    handleSearch();
    setShowResults(true);
  };

  const handleReset = () => {
    setAge([]);
    setGender([]);
    setCategory("");
    setShowResults(false);
  };

  const handleCheckboxChange = (setter: any, values: any, value: any) => {
    if (values.includes(value)) {
      setter(values.filter((item: any) => item !== value));
    } else {
      setter([...values, value]);
    }
  };

  const topInterests: InterestResult[] = showResults
    ? interestResults.sort((a, b) => b.population - a.population).slice(0, 3)
    : [];

  const topLocalTrends = showResults
    ? localTrendResult
        .sort((a, b) => b.interestMultiple - a.interestMultiple)
        .slice(0, 5)
    : [];

  const topLocalPopulation = showResults
    ? localPopulationResult
        .sort((a, b) => b.population - a.population)
        .slice(0, 5)
    : [];

  return (
    <div className={styles.container}>
      <h1>데모 프로그램</h1>
      <div className={styles["select-container"]}>
        <div className={styles.checkboxGroup}>
          <label>성별 선택</label>
          <label>
            <input
              type="checkbox"
              checked={gender.includes("male")}
              onChange={() => handleCheckboxChange(setGender, gender, "male")}
            />
            남자
          </label>
          <label>
            <input
              type="checkbox"
              checked={gender.includes("female")}
              onChange={() => handleCheckboxChange(setGender, gender, "female")}
            />
            여자
          </label>
        </div>

        <div className={styles.checkboxGroup}>
          <label>연령대 선택</label>
          {["0", "10", "20", "30", "40", "50", "60"].map((ageValue) => (
            <label key={ageValue}>
              <input
                type="checkbox"
                checked={age.includes(ageValue)}
                onChange={() => handleCheckboxChange(setAge, age, ageValue)}
              />
              {ageValue}대
            </label>
          ))}
        </div>

        <div className={styles.checkboxGroup}>
          <label>카테고리 선택</label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
          >
            <option value="">업종 카테고리</option>
            <option value="피부과">피부과</option>
            <option value="성형외과">성형외과</option>
            <option value="건강기구">건강기구</option>
            <option value="공연장, 웨딩홀 등 장소 대관">공연장, 웨딩홀 등 장소 대관</option>
            <option value="가수, 음반 등 문화콘텐츠">가수, 음반 등 문화콘텐츠</option>
          </select>
        </div>
      </div>

      <button className={styles["demo-button"]} onClick={handleSearchClick}>
        조회
      </button>
      <button className={styles["demo-button"]} onClick={handleReset}>
        초기화
      </button>

      {showResults && (
        <div className={styles.results}>
          <h1>오프라인광고 상위 3개 지역추천</h1>
          <ol className={styles["horizontal-list"]}>
            {topInterests.map((interest, index) => (
              <li key={index}>
                {index + 1}. {interest.localName}{" "}
                <span>({outDoorAd[interest.localName]}개)</span>
              </li>
            ))}
          </ol>
          <span className={styles["adNotice"]}>(괄호안의 수는 해당지역의 옥외광고 수)</span>
          <h2>선택한 카테고리에 따른 네이버 관심도 상위 5개 지역</h2>
          <ol>
            {topLocalTrends.map((trend, index) => (
              <li key={index}>
                {trend.siGunGu} ({trend.interestMultiple * 100}%)
              </li>
            ))}
          </ol>

          <h2>성별, 연령대에 따른 인구수 상위 5개 지역</h2>
          <ol>
            {topLocalPopulation.map((interest, index) => (
              <li key={index}>
                {interest.localName} ({interest.totalPopulation}명)
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

export default Demo;
